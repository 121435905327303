import React from 'react'
import './sectionTitle.Module.scss'

interface SectionTitleProps {
  children: React.ReactNode
  position?: 'left' | 'center'
}

export default function SectionTitle({
  children,
  position,
}: SectionTitleProps) {
  return (
    <div className={`sectiontitle sectiontitle--${position}`}>
      <div className="sectiontitle__separator" />
      {children}
      {position == 'center' && <div className="sectiontitle__separator" />}
    </div>
  )
}
