import React from 'react'
import { useNavigate } from 'react-router-dom'
import './logo.Module.scss'
import logoImage from 'assets/photos/logo.png'

export default function Logo() {
  const navigate = useNavigate()
  return (
    <div className="logo" onClick={() => navigate('/')}>
      <img className="logo__image" src={logoImage} />
    </div>
  )
}
