import React, { ChangeEventHandler } from 'react'
import './formInput.Module.scss'

interface FormInputProps {
  name?: string
  onChange?: ChangeEventHandler<HTMLInputElement>
  title?: string
  className?: string
  placeholder?: string
  type?: string
  value?: string
}

export default function FormInput({
  name,
  title,
  onChange,
  type = 'text',
  placeholder,
  className,
  value,
}: FormInputProps) {
  return (
    <div className="input">
      {title && <label>{title}</label>}
      <input
        type={type}
        onChange={onChange}
        name={name}
        value={value}
        placeholder={placeholder}
        className={className ? className : ''}
      />
    </div>
  )
}
