import React from 'react'
import './h3.Module.scss'

interface H3Props {
  children?: React.ReactNode
  color?: 'white' | 'black'
}

export default function H3({ children, color = 'black' }: H3Props) {
  return <h1 className={`h3 h3--${color}`}>{children}</h1>
}
