import SectionTitle from 'components/common/sectionTitle/sectionTitle'
import React from 'react'
import './whyChooseUs.Module.scss'
import H4 from 'components/common/h4/h4'
import { useTranslation } from 'react-i18next'
import {} from 'utils/i18next'

interface dataProps {
  title: string
  text: string
}

export default function WhyChooseUs() {
  const { t } = useTranslation()

  const data: dataProps[] = t('whyChooseUs.data', { returnObjects: true })

  return (
    <div className="whyus">
      <SectionTitle>{t('whyChooseUs.title')}</SectionTitle>
      <div className="whyus__container">
        {data.map((item) => {
          return (
            <div key={item.title} className="whyus__item">
              <div className="item__header">
                <div className="item__point" />
                <H4>{item.title}</H4>
              </div>
              <p>{item.text}</p>
            </div>
          )
        })}
      </div>
    </div>
  )
}
