import React from 'react'
import './h1.Module.scss'

interface H1Props {
  children?: React.ReactNode
  color?: 'white' | 'black'
}

export default function H1({ children, color = 'black' }: H1Props) {
  return <h1 className={`h1 h1--${color}`}>{children}</h1>
}
