import React from 'react'
import './h4.Module.scss'

interface H4Props {
  children?: React.ReactNode
  color?: 'white' | 'black'
}

export default function H4({ children, color = 'black' }: H4Props) {
  return <h1 className={`h4 h4--${color}`}>{children}</h1>
}
