import React from 'react'
import heroImage from 'assets/photos/image2.png'
import Button from 'components/common/button/button'
import H1 from 'components/common/h1/h1'
import H5 from 'components/common/h5/h5'
import './hero.Module.scss'
import { Star } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import {} from 'utils/i18next'

export default function Hero() {
  const { t } = useTranslation()

  return (
    <div
      className="hero"
      style={{ backgroundImage: `url("${heroImage}")` }}
      id="home"
    >
      <div className="hero__background" />
      <div className="hero__container">
        <div className="hero__body">
          <H1 color="white">{t('hero.title')}</H1>
          <H5 color="white">{t('hero.text')}</H5>
        </div>
        <div className="hero__footer">
          <Button
            size="large"
            onClick={() =>
              document
                .getElementById('contact')
                ?.scrollIntoView({ behavior: 'smooth' })
            }
          >
            {t('hero.buttonText')}
          </Button>
          <div className="hero__social__proof">
            <label>{t('hero.socialProof')}</label>
            <div className="hero__5star">
              <Star fontSize="inherit" />
              <Star fontSize="inherit" />
              <Star fontSize="inherit" />
              <Star fontSize="inherit" />
              <Star fontSize="inherit" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
