import React from 'react'
import Logo from './components/logo/logo'
import './header.Module.scss'
import Link from './components/link/link'
import Button from 'components/common/button/button'
import { useTranslation } from 'react-i18next'
import {} from 'utils/i18next'

export default function Header() {
  const { t } = useTranslation()

  return (
    <div className="header">
      <div className="header__container">
        <Logo />
        <div className="header__body">
          <div className="header__links">
            <Link section={{ page: '/', id: 'home' }}>
              {t('linkTranslation.home')}
            </Link>
            <Link section={{ page: '/', id: 'services' }}>
              {t('linkTranslation.services')}
            </Link>
            <Link section={{ page: '/', id: 'contact' }}>
              {t('linkTranslation.contact')}
            </Link>
          </div>
          <Button
            onClick={() =>
              document
                .getElementById('contact')
                ?.scrollIntoView({ behavior: 'smooth' })
            }
          >
            {t('header.headerButton')}
          </Button>
        </div>
      </div>
    </div>
  )
}
