import React from 'react'
import SectionTitle from 'components/common/sectionTitle/sectionTitle'
import image from 'assets/photos/image3.png'
import './about.Module.scss'
import H2 from 'components/common/h2/h2'
import H5 from 'components/common/h5/h5'
import { useTranslation } from 'react-i18next'
import {} from 'utils/i18next'

export default function About() {
  const { t } = useTranslation()

  return (
    <div className="about" id="about">
      <SectionTitle>{t('about.title')}</SectionTitle>
      <div className="about__container">
        <div className="about__body">
          <H2>{t('about.subtitle')}</H2>
          <H5>{t('about.text')}</H5>
        </div>
        <div
          className="about__image"
          style={{ backgroundImage: `url("${image}")` }}
        />
      </div>
    </div>
  )
}
