import React from 'react'
import './button.Module.scss'

interface ButtonProps {
  children?: React.ReactNode
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined
  className?: string
  variant?: 'primary' | 'tertiary'
  size?: 'normal' | 'large'
}

export default function Button({
  children,
  onClick,
  className,
  variant = 'primary',
  size = 'normal',
}: ButtonProps) {
  return (
    <button
      className={`button button--${size} button--${variant} ${className}`}
      onClick={onClick}
    >
      {children}
    </button>
  )
}
