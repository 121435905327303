import SectionTitle from 'components/common/sectionTitle/sectionTitle'
import React from 'react'
import './services.Module.scss'
import H3 from 'components/common/h3/h3'
import { useTranslation } from 'react-i18next'
import {} from 'utils/i18next'

interface dataProps {
  title: string
  text: string
}

export default function Services() {
  const { t } = useTranslation()

  const data: dataProps[] = t('services.data', {
    returnObjects: true,
  })

  return (
    <div className="services" id="services">
      <SectionTitle>{t('services.title')}</SectionTitle>
      <div className="services__container">
        {data.map((item: dataProps) => {
          return (
            <div key={item.title} className="services__item">
              <H3>{item.title}</H3>
              <p>{item.text}</p>
            </div>
          )
        })}
      </div>
    </div>
  )
}
