import React, { ChangeEventHandler } from 'react'
import './formTextArea.Module.scss'

interface FormTextAreaProps {
  name?: string
  onChange?: ChangeEventHandler<HTMLTextAreaElement>
  title?: string
  className?: string
  placeholder?: string
  value?: string
}

export default function FormTextArea({
  name,
  title,
  onChange,
  placeholder,
  className,
  value,
}: FormTextAreaProps) {
  return (
    <div className="textarea">
      {title && <label>{title}</label>}
      <textarea
        onChange={onChange}
        name={name}
        value={value}
        placeholder={placeholder}
        className={className ? className : ''}
      />
    </div>
  )
}
