import React from 'react'
import './h2.Module.scss'

interface H2Props {
  children?: React.ReactNode
  color?: 'white' | 'black'
}

export default function H2({ children, color = 'black' }: H2Props) {
  return <h1 className={`h2 h2--${color}`}>{children}</h1>
}
