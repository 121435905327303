import Button from 'components/common/button/button'
import FormInput from 'components/forms/formInput/formInput'
import React, {
  ChangeEventHandler,
  FormEventHandler,
  useRef,
  useState,
} from 'react'
import FormTextArea from 'components/forms/formTextArea/formTextArea'
import emailjs from '@emailjs/browser'
import { ToastContainer, toast, Bounce } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './contactForm.Module.scss'
import { useTranslation } from 'react-i18next'
import {} from 'utils/i18next'

interface formProps {
  name: string
  email: string
  details: string
}

export default function ContactForm() {
  const { t } = useTranslation()
  //eslint-disable-next-line
  const formRef: any = useRef(null)

  const [formData, setFormData] = useState<formProps>({
    name: '',
    email: '',
    details: '',
  })

  const handleChange: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    const { name, value } = e.target
    setFormData((oldFormData) => {
      return { ...oldFormData, [name]: value }
    })
  }

  const onSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault()

    const serviceID = process.env.REACT_APP_EMAILJS_SERVICE_ID as string
    const templateID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID as string
    const publicKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY as string

    emailjs
      //eslint-disable-next-line
      .send(serviceID, templateID, formData as any, publicKey)
      .then(() => {
        toast('Thanks for your interest!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
          transition: Bounce,
        })
        setFormData({ name: '', details: '', email: '' })
      })
      .catch(() => {
        toast.error('Some error occured!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
          transition: Bounce,
        })
        setFormData({ name: '', email: '', details: '' })
      })
  }

  return (
    <div className="contactform">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        transition={Bounce}
      />
      <form
        ref={formRef}
        onSubmit={onSubmit}
        className="contactform__container"
      >
        <div className="contactform__body">
          <div className="contactform__row">
            <FormInput
              name="name"
              title={t('contact.contactForm.name')}
              placeholder={t('contact.contactForm.name')}
              onChange={handleChange}
            />

            <FormInput
              name="email"
              type="email"
              title={t('contact.contactForm.email')}
              placeholder={t('contact.contactForm.email')}
              onChange={handleChange}
            />
          </div>
          <FormTextArea
            onChange={handleChange}
            name="details"
            placeholder={t('contact.contactForm.details')}
            title={t('contact.contactForm.details')}
          />
        </div>
        <Button size="large">{t('contact.contactForm.button')}</Button>
      </form>
    </div>
  )
}
