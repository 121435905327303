import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

const userLanguage = navigator.language.split('-')[0]

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: require('../locales/en/translation.json'),
    },
    ro: {
      translation: require('../locales/ro/translation.json'),
    },
  },

  lng: userLanguage,
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
})

export default i18n
