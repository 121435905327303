import React from 'react'
import './h5.Module.scss'

interface H5Props {
  children?: React.ReactNode
  color?: 'white' | 'black'
}

export default function H5({ children, color = 'black' }: H5Props) {
  return <h1 className={`h5 h5--${color}`}>{children}</h1>
}
