import React from 'react'
import Hero from './components/hero/hero'
import WhyChooseUs from './components/whyChooseUs/whyChooseUs'
import About from './components/about/about'
import Services from './components/services/services'
import Contact from './components/contact/contact'

export default function Home() {
  return (
    <>
      <Hero />
      <WhyChooseUs />
      <About />
      <Services />
      <Contact />
    </>
  )
}
