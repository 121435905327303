import React from 'react'
import { useNavigate } from 'react-router-dom'
import './link.Module.scss'

interface LinkProps {
  children: React.ReactNode
  link?: string
  section?: {
    page: string
    id: string
  }
}

export default function Link({ children, link, section }: LinkProps) {
  const navigate = useNavigate()
  return (
    <>
      {link != null && (
        <label onClick={() => navigate(link)} className="link">
          {children}
        </label>
      )}

      {link == null && section && (
        <label
          onClick={() => {
            if (window.location.pathname != section.page) {
              navigate(section.page)
            }
            setTimeout(() => {
              document.getElementById(section.id)?.scrollIntoView({
                behavior: 'smooth',
              })
            }, 125)
          }}
          className="link"
        >
          {children}
        </label>
      )}
    </>
  )
}
