import { Facebook, Instagram, LinkedIn } from '@mui/icons-material'
import React from 'react'
import './footer.Module.scss'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {} from 'utils/i18next'

export default function Footer() {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const goToSection = (path: string, id: string) => {
    if (window.location.pathname != 'path') {
      navigate(path)
    }
    setTimeout(() => {
      document.getElementById(id)?.scrollIntoView({ behavior: 'smooth' })
    }, 125)
  }

  return (
    <div className="footer">
      <div className="footer__container">
        <div className="footer__item ">
          <div className="footer__column">
            <label className="column__header">Company</label>
            <div className="column__body">
              <label onClick={() => goToSection('/', 'home')}>
                {t('linkTranslation.home')}
              </label>
              <label onClick={() => goToSection('/', 'about')}>
                {t('linkTranslation.about')}
              </label>
              <label onClick={() => goToSection('/', 'services')}>
                {t('linkTranslation.services')}
              </label>
              <label onClick={() => goToSection('/', 'contact')}>
                {t('linkTranslation.contact')}
              </label>
            </div>
          </div>

          <div className="footer__column">
            <label className="column__header">Legal</label>
            <div className="column__body">
              <label onClick={() => goToSection('/', 'home')}>
                Terms & Conditions
              </label>
              <label onClick={() => goToSection('/', 'about')}>
                Privacy Policy
              </label>
              <label onClick={() => goToSection('/', 'services')}>GDPR</label>
            </div>
          </div>
        </div>
        <div className="footer__item footer__item--vertical">
          <div className="footer__social">
            <div>
              <LinkedIn fontSize="inherit" />
            </div>
            <div>
              <Facebook fontSize="inherit" />
            </div>
            <div>
              <Instagram fontSize="inherit" />
            </div>
          </div>
          <label className="footer__copyright">© All Rights Reserved</label>
        </div>
      </div>
    </div>
  )
}
