import SectionTitle from 'components/common/sectionTitle/sectionTitle'
import React from 'react'
import image from 'assets/photos/image1.png'
import H4 from 'components/common/h4/h4'
import './contact.Module.scss'
import ContactForm from './components/contactForm/contactForm'
import { useTranslation } from 'react-i18next'
import {} from 'utils/i18next'

export default function Contact() {
  const { t } = useTranslation()

  return (
    <div
      className="contact"
      id="contact"
      style={{ backgroundImage: `url("${image}")` }}
    >
      <div className="contact__body">
        <SectionTitle position="center">{t('contact.title')}</SectionTitle>
        <div className="contact__container">
          <H4 color="white">{t('contact.text')}</H4>
          <ContactForm />
        </div>
      </div>
    </div>
  )
}
